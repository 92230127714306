import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from './assets/img/logo_raw.png';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navLinkRef = useRef([]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const linkAction = () => {
    navLinkRef.current.forEach((n) => n.classList.remove('show-menu'));
  };

  useEffect(() => {
    navLinkRef.current.forEach((n) => n.addEventListener('click', linkAction));

    return () => {
      navLinkRef.current.forEach((n) => n.removeEventListener('click', linkAction));
    };
  }, []);

  const [darkTheme, setDarkTheme] = useState(false);

  const themeButtonHandler = () => {
    setDarkTheme((prevTheme) => !prevTheme);
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem('selected-theme');

    if (storedTheme) {
      setDarkTheme(storedTheme === 'dark');
    }
  }, []);

  useEffect(() => {
    document.body.classList.toggle('dark-theme', darkTheme);
    localStorage.setItem('selected-theme', darkTheme ? 'dark' : 'light');
  }, [darkTheme]);

  return (
    <header className="l-header" id="header">
      <nav className="nav bd-container">
        <div className="header__content">
          <div className="header__logo-container">
            <img src={Logo} width={60} height={60} alt="ABIP UK Logo" className="header__logo"  style={{marginTop:"10px"}}/>
            {/* <a href="#" className="header__name">ABIP UK</a> */}
          </div>
        </div>
        <div className={`nav__menu ${showMenu ? 'show-menu' : ''}`} id="nav-menu" ref={(el) => (navLinkRef.current = [el])}>
          <ul className="nav__list">
            <li className="nav__item"><a href="#home" className="nav__link active-link">Home</a></li>
            <li className="nav__item"><a href="#about" className="nav__link">About</a></li>
            <li className="nav__item"><a href="#services" className="nav__link">Services</a></li>
            <li className="nav__item"><a href="#events" className="nav__link">Events</a></li>
            <li className="nav__item"><a href="#contact" className="nav__link">Contact us</a></li>
            <li><i className={`bx ${darkTheme ? 'bx-moon' : 'bx-sun'} change-theme`} id="theme-button" onClick={themeButtonHandler}></i></li>
          </ul>
        </div>
        <div className="nav__toggle" onClick={toggleMenu} id="nav-toggle">
          <i className="bx bx-menu"></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
