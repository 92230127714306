import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';

const Services = () => {
  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'top',
      distance: '30px',
      duration: 2000,
      reset: true,
    });

    sr.reveal('.services__container', {
      interval: 200,
    });

    return () => {
      sr.destroy();
    };
  }, []);

  return (
    <section className="services section bd-container" id="services">
      <span className="section-subtitle">Offering</span>
      <h2 className="section-title">Our Services</h2>

      <div className="services__container bd-grid">
        <div className="services__content">
          <h3 className="services__title">Facilitating Experience & Opportunities</h3>
          <p className="services__description">
            We understand the unique challenges faced by our members in the UK's competitive IT landscape. 
          </p>
        </div>

        <div className="services__content">
          <h3 className="services__title">In-House Learning Opportunities</h3>
          <p className="services__description">
          We believe in continuous learning and skill enhancement. ABIP offers a range of in-house training programs, workshops, and seminars tailored to the evolving demands of the IT sector.

            </p>
        </div>

        <div className="services__content">
          <h3 className="services__title">Collaboration with Recruitment Agencies</h3>
          <p className="services__description">
            Recognizing the importance of strong professional networks, we have established collaborations with leading recruitment agencies in the UK.
          </p>
        </div>

        <div className="services__content">
          <h3 className="services__title">Comprehensive Career Support</h3>
          <p className="services__description">
            Our journey with our members doesn't just stop at finding a job. We provide ongoing support.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
