import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';

const Footer = () => {
  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'top',
      distance: '30px',
      duration: 2000,
      reset: true,
    });

    sr.reveal('.footer__container', {
      interval: 200,
    });

    return () => {
      sr.destroy();
    };
  }, []);

  return (
    <footer className="footer section bd-container">
      <div className="footer__container bd-grid">
        <div className="footer__content">
          <a href="#" className="footer__logo">ABIP UK</a>
          <span className="footer__description">Association of Bangladeshi IT Professionals in the UK</span>
          <div>
            {/* Add your social media links */}
            <a href="#" className="footer__social"><i className='bx bxl-facebook'></i></a>
            <a href="#" className="footer__social"><i className='bx bxl-instagram'></i></a>
            <a href="#" className="footer__social"><i className='bx bxl-twitter'></i></a>
          </div>
        </div>

        <div className="footer__content">
          <h3 className="footer__title">Services</h3>
          <ul>
            <li><a href="#services" className="footer__link">Networking</a></li>
            <li><a href="#services" className="footer__link">Mentoring and training</a></li>
            <li><a href="#services" className="footer__link">Software Development</a></li>
            <li><a href="#services" className="footer__link">Events and Activities</a></li>
          </ul>
        </div>

        <div className="footer__content">
          <h3 className="footer__title">Information</h3>
          <ul>
            <li><a href="#events" className="footer__link">Upcoming Events</a></li>
            <li><a href="#about" className="footer__link">About Us</a></li>
            <li><a href="#contact" className="footer__link">Contact Us</a></li>
          </ul>
        </div>

        <div className="footer__content">
          <h3 className="footer__title">Contact</h3>
          <ul>
            <li>Association of Bangladeshi IT Professionals in the UK</li>
            <li>London, United Kingdom</li>
            <li>Email: info@abipuk.org</li>
          </ul>
        </div>
      </div>

      <p className="footer__copy">&#169; 2024 Association of Bangladeshi IT Professionals in the UK. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
