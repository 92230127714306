import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';

const Events = () => {
  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'top',
      distance: '30px',
      duration: 2000,
      reset: true,
    });

    sr.reveal('.events__container', {
      interval: 200,
    });

    return () => {
      sr.destroy();
    };
  }, []);

  return (
    <section className="events section bd-container" id="events">
      <span className="section-subtitle">Upcoming Events</span>
      <h2 className="section-title">Explore Our Events</h2>

      <div className="events__container bd-grid">
        {/* Event 1 */}
        <div className="events__content">
          <h3 className="events__title">Event Title 1</h3>
          <p className="events__date">Date: January 15, 2024</p>
          <p className="events__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin euismod justo ac lacus dapibus, vel fringilla neque vestibulum.
          </p>
        </div>

        {/* Event 2 */}
        <div className="events__content">
          <h3 className="events__title">Event Title 2</h3>
          <p className="events__date">Date: February 10, 2024</p>
          <p className="events__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin euismod justo ac lacus dapibus, vel fringilla neque vestibulum.
          </p>
        </div>

        {/* Event 3 */}
        <div className="events__content">
          <h3 className="events__title">Event Title 3</h3>
          <p className="events__date">Date: March 5, 2024</p>
          <p className="events__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin euismod justo ac lacus dapibus, vel fringilla neque vestibulum.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Events;
