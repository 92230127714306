import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import HomeImg from './assets/img/home.png';

const Home = () => {
  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'top',
      distance: '30px',
      duration: 2000,
      reset: true,
    });

    sr.reveal('.home__container', {
      interval: 200,
    });

    return () => {
      sr.destroy();
    };
  }, []);

  return (
    <section className="home" id="home">
      <div className="home__container bd-container bd-grid">
        <div className="home__data">
          <h2 className="home__subtitle --mb-3">Join ABIP and be together!</h2>
          <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop</p>
          <a  href="#register" className="button " style={{ marginTop: '20px' }}>
            Became a Member Now!
          </a>
        </div>
        <img src={HomeImg} alt="Home" className="home__img" />
      </div>
    </section>
  );
};

export default Home;
