import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import aboutImg from './assets/img/about.jpg';

const About = () => {
  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'top',
      distance: '30px',
      duration: 1000,
      reset: true,
    });

    sr.reveal('.about__data, .about__description, .button, .about__img', {
      interval: 200,
    });

    return () => {
      sr.destroy();
    };
  }, []);

  return (
    <section className="about section bd-container" id="about">
      <div className="about__container bd-grid">
        <div className="about__data">
          <span className="section-subtitle about__initial">About Us</span>
          <h2 className="section-title about__initial">
            A community for tech people.
          </h2>
          <p className="about__description">
            At ABIP UK, we are a vibrant community of Bangladeshi IT experts,
            residing and working beyond our homeland's borders. Our core mission
            is to empower fellow IT professionals to navigate and excel in the
            dynamic UK tech industry.
          </p>
          <a href="#register" className="button">
Contact Us   </a>
        </div>
        <img src={aboutImg} alt="About ABIP UK" className="about__img" />
      </div>
    </section>
  );
};

export default About;
